import styled from 'styled-components';

const TextRightImageLeftComponent = ({topLine, topLineColor, headline, headlineColor, descriptionColor, description, img, alt}) => {
  const imgStart = true;

  const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;

    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 1400px) {
      grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};  
    }

    @media screen and (max-width: 768px) {
      grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}; 
    }

    @media screen and (max-width: 480px) {
      grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
  `;
  
  const Column1 = styled.div`
    margin-left: 24px;
    grid-area: col1;

    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
      margin-left: 0px;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 24px;
      margin-left: 0px;
    }
  `;
  
  const Column2 = styled.div`
    /* margin-bottom: 10px; */
    grid-area: col2;
  `;
  
  const TextWrapper = styled.div`
    max-width: 100%;
    /* padding-top: 0; */
  `;

  
  const Heading = styled.h2`
    color:  ${headlineColor};
  `;
  
  const Subtitle = styled.p`
    color: ${descriptionColor};
  `;
  
  const ImageWrapper = styled.div`
    /* max-width: 555px; */
    width: 100%;
    height: 100%;
  `;
  
  const Img = styled.img`
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      height: 80%;
    }

    @media screen and (max-width: 480px) {
      height: 60%;
    }
  `;
  
  return (
    <>
      <InfoRow imgStart={imgStart}>
        <Column1>
          <TextWrapper>
            <Heading>{headline}</Heading>
            <Subtitle>{description}</Subtitle>
          </TextWrapper>
        </Column1>

        <Column2>
          <ImageWrapper>
            <Img src={img} alt={alt} loading="lazy" width="1080px" height="715px"/>
          </ImageWrapper>
        </Column2>
      </InfoRow>
    </>
  );
};

export default TextRightImageLeftComponent;