import styled from 'styled-components';
import GlobalSettings from './common/GlobalSettings';

const MainSectionContainer = (backgroundColor) => {

  const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: ${backgroundColor};
    padding-bottom: 64px;
    padding-top: 64px;
    row-gap: ${GlobalSettings.spaceAroundHTMLElements};
    column-gap: ${GlobalSettings.spaceAroundHTMLElements};

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 768px) {
      padding-right: 34px;
      padding-left: 34px;
    }

    @media screen and (max-width: 480px) {
      padding-right: ${GlobalSettings.paddingAroundHTMLElements};
      padding-left: ${GlobalSettings.paddingAroundHTMLElements};
    }
  `;

  return MainContainer;
}

export default MainSectionContainer;