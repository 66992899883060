import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import GlobalSettings from '../components/common/GlobalSettings';
import MainSectionContentsWrapper from '../components/MainSectionContentsWrapper';
import ButtonCardComponent from '../components/ButtonCardComponent';
// import ButtonCardComponentAnchorLink from '../components/ButtonCardComponentAnchorLink';
import MainSectionContainer from '../components/MainSectionContainer';
import TextLeftImageRightComponent from '../components/TextLeftImageRightComponent'
import TextRightImageLeftComponent from '../components/TextRightImageLeftComponent'
import landing_page_video from '../videos/rdct-research-development-center-technology-anniversaire.mp4';
import appliedResearchCenterImg from '../images/home/rdct-applied-research-center.webp'
import solutionsCenterImg from '../images/home/rdct-solutions-center.webp'
import educationCenterImg from '../images/home/rdct-education-center.webp'
import consultingCenterImg from '../images/home/rdct-consulting-center.webp'

import ManagementImage from '../images/research-fields/rdct-cameroun-cameroon-douala-bonaberi-research-center-fields-management-business-people.webp';
import MenuCleanImage from '../images/solutions/agence-clean-menageres-nounous-recrutement-recherche-menage-service-domicile-homme-femme-menage-agents-entretien.webp';
import MenuBookSharingImage from '../images/solutions/cameroun-douala-book-sharing.webp';
import TrainingSearchEngineOptimizationImage from '../images/training-courses/search-engine-optimization/rdct-education-center-training-courses-web-analytics.webp';
import TrainingMicrosoftOfficeImage from '../images/training-courses/microsoft-office/training-formation-microsoft-office-secretariat-bureautique-word-excel-powerpoint.webp';
import TrainingDigitalMarketingImage from '../images/training-courses/digital_marketing/digital-community-social-media-management-email-sms-marketing-techniques.webp';
import TrainingWebDevelopmentFrontendImage from '../images/training-courses/web-development-frontend/training-web-development-frontend-html-css-javascript-react.webp';
import TrainingWebDevelopmentBackendImage from '../images/training-courses/web-develpment-backend/training-web-development-backend-mysql-database.webp';
import ConsultingCenterImage from '../images/consulting-center/rdct-consulting-center-company-consultancy-services.webp';
import SupportForTrainingInGermanyImage from '../images/accompagnement/accompagnement-procedure-demande-visa-formation-salaire-allemagne.webp';



const VideoSection = () => {

    const MainContainer = styled.div`
        background: transparent;
        // opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        height: 800px;

        @media screen and (max-width: 1400px) {
            height: 700px;
        }

        @media screen and (max-width: 768px) {
            height: 500px;
        }

        @media screen and (max-width: 480px) {
            height: 300px;
        }

        :before {
            content: '';
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background: transparent;
        }
    `;

    const VideoContainer = styled.div`
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    `;

    const VideoBg = styled.video`
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: rgba(0, 0, 0, 0.1);
    `;

    const TextContainer = styled.div`
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);

        margin: 64px;
        padding: 10px;

        @media screen and (max-width: 1400px) {
        margin: 64px;
        }

        @media screen and (max-width: 768px) {
        margin: 34px;
        }

        @media screen and (max-width: 480px) {
        margin: 14px;
        }
    `;

    const TextH1 = styled.h1`
        color: ${GlobalSettings.mainWhiteColor};
        text-align: center;
    `;

    const TextP = styled.p`
        color: ${GlobalSettings.mainWhiteColor};
        text-align: center;
    `;

    const {t} = useTranslation('home');

    return (

        <MainContainer>
            <VideoContainer>
                <VideoBg autoPlay loop muted src={landing_page_video} type='video/mp4' />
            </VideoContainer>

            <TextContainer>
                <TextH1>{t('video_section_welcome_message.slogan')}</TextH1>
                <TextP>{t('video_section_welcome_message.location')}</TextP>
            </TextContainer>
        </MainContainer>
    );
};

const AppliedResearchCenterSection = () => {

    const AppliedResearchDescriptionSection = () => {

        const {t} = useTranslation('home');
    
        const AppliedResearchCenterObject = {
            id: 'about-applied-research',
            topLine: t('applied_research_center_section.topLine'),
            topLineColor: GlobalSettings.mainYellowColor,
            headline: t('applied_research_center_section.headline'),
            headlineColor: GlobalSettings.mainDarkColor,
            description: t('applied_research_center_section.description'),
            descriptionColor: GlobalSettings.mainDarkColor,
            img: appliedResearchCenterImg,
            alt: t('applied_research_center_section.alt')
        }
    
        const MainContainer = MainSectionContainer(GlobalSettings.mainWhiteColor);
    
        const ContainerWrapper = styled.div`
            display: grid;
            align-items: center;
        `;
    
        return (
            <MainContainer id="rdct-cameroun-cameroon-douala-bonaberi-applied-research-center">
                <ContainerWrapper>
                    <TextLeftImageRightComponent {...AppliedResearchCenterObject} />
                </ContainerWrapper>
            </MainContainer>
        );
    }

    const AppliedResearchFieldsSection = () => {

        const MainContainer = MainSectionContainer(GlobalSettings.mainWhiteColor);
      
        const ContainerWrapper = MainSectionContentsWrapper();
      
        const TextH2 = styled.h2`
          color: ${GlobalSettings.surrogateDarkColor};
        `;
      
        const {t} = useTranslation('header_footer');
      
        return (
          <MainContainer>
            <TextH2>{t('research_fields_description')}</TextH2>
              <ContainerWrapper>
              <ButtonCardComponent
              description={t('web_technologies_menu.description')}
              imageSrc={TrainingWebDevelopmentFrontendImage}
              title={t('web_technologies_menu.title')}
              toref={t('web_technologies_menu.canonical_url')}
              alText={t('web_technologies_menu.description')}
              bgColor={GlobalSettings.mainWhiteColor}
              titleColor={GlobalSettings.mainDarkColor}
              txtColor={GlobalSettings.mainDarkColor}
              btnText={t('web_technologies_menu.learn_more')}
          />
          <ButtonCardComponent
              description={t('digital_marketing_menu.description')}
              imageSrc={TrainingDigitalMarketingImage}
              title={t('digital_marketing_menu.title')}
              toref={t('digital_marketing_menu.canonical_url')}
              alText={t('digital_marketing_menu.description')}
              bgColor={GlobalSettings.mainWhiteColor}
              titleColor={GlobalSettings.mainDarkColor}
              txtColor={GlobalSettings.mainDarkColor}
              btnText={t('digital_marketing_menu.learn_more')}
          />
          <ButtonCardComponent
              description={t('management_menu.description')}
              imageSrc={ManagementImage}
              title={t('management_menu.title')}
              toref={t('management_menu.canonical_url')}
              alText={t('management_menu.description')}
              bgColor={GlobalSettings.mainWhiteColor}
              titleColor={GlobalSettings.mainDarkColor}
              txtColor={GlobalSettings.mainDarkColor}
              btnText={t('management_menu.learn_more')}
          />
              </ContainerWrapper>
          </MainContainer>
        );
      
    };

    return (
        <>
            <AppliedResearchDescriptionSection />
            <AppliedResearchFieldsSection />
        </>
    );
}

const SolutionsCenterSection = () => {

    const SolutionsCenterDescriptionSection = () => {

        const {t} = useTranslation('home');
    
        const SolutionsCenterObject = {
            id: 'about-solutions-center',
            topLine: t('solutions_center_section.topLine'),
            topLineColor: GlobalSettings.mainYellowColor,
            headline: t('solutions_center_section.headline'),
            headlineColor: GlobalSettings.mainYellowColor,
            description: t('solutions_center_section.description'),
            descriptionColor: GlobalSettings.mainWhiteColor,
            img: solutionsCenterImg,
            alt: t('solutions_center_section.alt')
        }
    
        const MainContainer = MainSectionContainer(GlobalSettings.surrogateDarkColor);
    
        const ContainerWrapper = styled.div`
            display: grid;
            align-items: center;
        `;
    
        return (
            <MainContainer id="rdct-cameroun-cameroon-douala-bonaberi-solutions-center">
                <ContainerWrapper>
                    <TextRightImageLeftComponent {...SolutionsCenterObject} />
                </ContainerWrapper>
            </MainContainer>
        );
    }

    const SolutionsSection = () => {

        const MainContainer = MainSectionContainer(GlobalSettings.surrogateDarkColor);
      
        const ContainerWrapper = MainSectionContentsWrapper();
      
        const TextH2 = styled.h2`
          color: ${GlobalSettings.mainYellowColor};
        `;
      
        const {t} = useTranslation('header_footer');
      
        return (
          <MainContainer>
            <TextH2>{t('solutions_description')}</TextH2>
            <ContainerWrapper>
                <ButtonCardComponent
                    description={t('clean_agency_menu.description')}
                    imageSrc={MenuCleanImage}
                    title={t('clean_agency_menu.title')}
                    toref="https://clean.rdct.tech"
                    alText={t('clean_agency_menu.description')}
                    bgColor={GlobalSettings.surrogateDarkColor}
                    titleColor={GlobalSettings.mainWhiteColor}
                    txtColor={GlobalSettings.mainWhiteColor}
                    btnText={t('clean_agency_menu.learn_more')}
                />
                <ButtonCardComponent
                    description={t('book_sharing_menu.description')}
                    imageSrc={MenuBookSharingImage}
                    title={t('book_sharing_menu.title')}
                    toref="http://booksharing.rdct.tech"
                    alText={t('book_sharing_menu.description')}
                    bgColor={GlobalSettings.surrogateDarkColor}
                    titleColor={GlobalSettings.mainWhiteColor}
                    txtColor={GlobalSettings.mainWhiteColor}
                    btnText={t('book_sharing_menu.learn_more')}
                />
            </ContainerWrapper>
          </MainContainer>
        );
      
    };

    return (
        <>
            <SolutionsCenterDescriptionSection />
            <SolutionsSection />
        </>
    );
}

const EducationCenterSection = () => {

    const EducationCenterDescriptionSection = () => {

        const {t} = useTranslation('home');
    
        const EducationCenterObject = {
            id: 'about-education-center',
            topLine: t('education_center_section.topLine'),
            topLineColor: GlobalSettings.mainYellowColor,
            headline: t('education_center_section.headline'),
            headlineColor: GlobalSettings.mainDarkColor,
            description: t('education_center_section.description'),
            descriptionColor: GlobalSettings.mainDarkColor,
            img: educationCenterImg,
            alt: t('education_center_section.alt')
        }
    
        const MainContainer = MainSectionContainer(GlobalSettings.mainWhiteColor);
    
        const ContainerWrapper = styled.div`
            display: grid;
            align-items: center;
        `;
    
        return (
            <MainContainer id="rdct-cameroun-cameroon-douala-bonaberi-solutions-center">
                <ContainerWrapper>
                    <TextLeftImageRightComponent {...EducationCenterObject} />
                </ContainerWrapper>
            </MainContainer>
        );
    }

    const EducationCenterTrainingSection = () => {

        const MainContainer = MainSectionContainer(GlobalSettings.mainWhiteColor);
      
        const ContainerWrapper = MainSectionContentsWrapper();
      
        const TextH2 = styled.h2`
          color: ${GlobalSettings.surrogateDarkColor};
        `;
      
        const {t} = useTranslation('header_footer');
      
        return (
          <MainContainer>
            <TextH2>{t('training_description')}</TextH2>
              <ContainerWrapper>
                <ButtonCardComponent
                    description={t('training_web_development_frontend_menu.description')}
                    imageSrc={TrainingWebDevelopmentFrontendImage}
                    title={t('training_web_development_frontend_menu.title')}
                    toref={t('training_web_development_frontend_menu.canonical_url')}
                    alText={t('training_web_development_frontend_menu.description')}
                    bgColor={GlobalSettings.mainWhiteColor}
                    titleColor={GlobalSettings.mainDarkColor}
                    txtColor={GlobalSettings.mainDarkColor}
                    btnText={t('training_web_development_frontend_menu.learn_more')}
                />
                <ButtonCardComponent
                    description={t('training_web_development_backend_menu.description')}
                    imageSrc={TrainingWebDevelopmentBackendImage}
                    title={t('training_web_development_backend_menu.title')}
                    toref={t('training_web_development_backend_menu.canonical_url')}
                    alText={t('training_web_development_backend_menu.description')}
                    bgColor={GlobalSettings.mainWhiteColor}
                    titleColor={GlobalSettings.mainDarkColor}
                    txtColor={GlobalSettings.mainDarkColor}
                    btnText={t('training_web_development_backend_menu.learn_more')}
                />
                <ButtonCardComponent
                    description={t('training_search_engine_optimization_menu.description')}
                    imageSrc={TrainingSearchEngineOptimizationImage}
                    title={t('training_search_engine_optimization_menu.title')}
                    toref={t('training_search_engine_optimization_menu.canonical_url')}
                    alText={t('training_search_engine_optimization_menu.description')}
                    bgColor={GlobalSettings.mainWhiteColor}
                    titleColor={GlobalSettings.mainDarkColor}
                    txtColor={GlobalSettings.mainDarkColor}
                    btnText={t('training_search_engine_optimization_menu.learn_more')}
                />
                <ButtonCardComponent
                    description={t('training_digital_marketing_menu.description')}
                    imageSrc={TrainingDigitalMarketingImage}
                    title={t('training_digital_marketing_menu.title')}
                    toref={t('training_digital_marketing_menu.canonical_url')}
                    alText={t('training_digital_marketing_menu.description')}
                    bgColor={GlobalSettings.mainWhiteColor}
                    titleColor={GlobalSettings.mainDarkColor}
                    txtColor={GlobalSettings.mainDarkColor}
                    btnText={t('training_digital_marketing_menu.learn_more')}
                />
                <ButtonCardComponent
                    description={t('training_microsoft_office_menu.description')}
                    imageSrc={TrainingMicrosoftOfficeImage}
                    title={t('training_microsoft_office_menu.title')}
                    toref={t('training_microsoft_office_menu.canonical_url')}
                    alText={t('training_microsoft_office_menu.description')}
                    bgColor={GlobalSettings.mainWhiteColor}
                    titleColor={GlobalSettings.mainDarkColor}
                    txtColor={GlobalSettings.mainDarkColor}
                    btnText={t('training_microsoft_office_menu.learn_more')}
                />
            </ContainerWrapper>
          </MainContainer>
        );
      
    };

    return (
        <>
            <EducationCenterDescriptionSection />
            <EducationCenterTrainingSection />
        </>
    );
}

const ConsultingCenterSection = () => {

    const ConsultingCenterDescriptionSection = () => {

        const {t} = useTranslation('home');
    
        const ConsultingCenterObject = {
            id: 'about-consulting-center',
            topLine: t('consulting_center_section.topLine'),
            topLineColor: GlobalSettings.mainYellowColor,
            headline: t('consulting_center_section.headline'),
            headlineColor: GlobalSettings.mainYellowColor,
            description: t('consulting_center_section.description'),
            descriptionColor: GlobalSettings.mainWhiteColor,
            img: consultingCenterImg,
            alt: t('consulting_center_section.alt')
        }
    
        const MainContainer = MainSectionContainer(GlobalSettings.surrogateDarkColor);
    
        const ContainerWrapper = styled.div`
            display: grid;
            align-items: center;
        `;
    
        return (
            <MainContainer>
                <ContainerWrapper>
                    <TextRightImageLeftComponent {...ConsultingCenterObject} />
                </ContainerWrapper>
            </MainContainer>
        );
    }


    const ConsultingServicesSection = () => {

        const MainContainer = MainSectionContainer(GlobalSettings.surrogateDarkColor);
      
        const ContainerWrapper = MainSectionContentsWrapper();
      
        const TextH2 = styled.h2`
          color: ${GlobalSettings.mainYellowColor};
        `;
      
        const {t} = useTranslation('header_footer');
      
        return (
          <MainContainer>
            <TextH2>{t('consulting_services_description')}</TextH2>
            <ContainerWrapper>
                <ButtonCardComponent
                    description={t('support_for_visa_menu.description')}
                    imageSrc={SupportForTrainingInGermanyImage}
                    title={t('support_for_visa_menu.title')}
                    toref={t('support_for_visa_menu.canonical_url')}
                    alText={t('support_for_visa_menu.description')}
                    bgColor={GlobalSettings.surrogateDarkColor}
                    titleColor={GlobalSettings.mainWhiteColor}
                    txtColor={GlobalSettings.mainWhiteColor}
                    btnText={t('support_for_visa_menu.learn_more')}
                />
                
                <ButtonCardComponent
                    description={t('consulting_services_menu.description')}
                    imageSrc={ConsultingCenterImage}
                    title={t('consulting_services_menu.title')}
                    toref={t('consulting_services_menu.canonical_url')}
                    alText={t('consulting_services_menu.description')}
                    bgColor={GlobalSettings.surrogateDarkColor}
                    titleColor={GlobalSettings.mainWhiteColor}
                    txtColor={GlobalSettings.mainWhiteColor}
                    btnText={t('consulting_services_menu.learn_more')}
                />
            </ContainerWrapper>
          </MainContainer>
        );
      
    };

    return (
        <>
            <ConsultingCenterDescriptionSection />
            <ConsultingServicesSection />
        </>
    );
}

const Home = () => {

    const {t} = useTranslation('home');

    return (
        <>
            <Helmet htmlAttributes={{lang : t('lng')}}>
                <title>{t('home_page_title')}</title>
                <meta name="description" content={t('home_page_description')} />
                <link rel='canonical' hreflang={t('canonical_language_country_code')} href={t('canonical_url')} />
                <link rel="alternate" hreflang={t('alternate_language_country_code')} href={t('alternate_url')} />

                <link rel='canonical' hreflang={t('canonical_language_country_code')} href={t('www_canonical_url')} />
                <link rel="alternate" hreflang={t('alternate_language_country_code')} href={t('www_alternate_url')} />
            </Helmet>

            <article>
                <VideoSection />
            </article>
            
            <article>
                <AppliedResearchCenterSection />
            </article>
            
            <article>
                <SolutionsCenterSection />
            </article>
            
            <article>
                <EducationCenterSection />
            </article>
            
            <article>
                <ConsultingCenterSection />
            </article>
        </>
    );
    
};

export default Home;