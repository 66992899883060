import styled from 'styled-components';
import GlobalSettings from './common/GlobalSettings';

const MainSectionContentsWrapper = () => {

  const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    row-gap: ${GlobalSettings.spaceAroundHTMLElements};
    column-gap: ${GlobalSettings.spaceAroundHTMLElements};

    @media screen and (max-width: 480px) {
      justify-content: center;
    }
  `;

  return ContainerWrapper;
}

export default MainSectionContentsWrapper;