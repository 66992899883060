import React from "react";
import styled from "styled-components";
import GlobalSettings from './common/GlobalSettings';

const ButtonCardComponent = ({description, imageSrc, title, toref, alText, bgColor, titleColor, txtColor, btnText}) => {

  const MainContainer = styled.div`
    background-color: ${bgColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    border-radius: none;
    padding: 0px;
    
    flex-basis: 30%;

    @media screen and (max-width: 768px) {
      flex-basis: 40%;
    }

    @media screen and (max-width: 480px) {
      flex-basis: 100%;
    }
  `;

  const ContentContainer = styled.div`
    background-color: ${bgColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    border-radius: none;
    padding: ${GlobalSettings.paddingAroundHTMLElements};
  `;

  const Image = styled.img`
    width: 100%;
    height: 50%;

    @media screen and (max-width: 768px) {
      height: 50%;
    }

    @media screen and (max-width: 480px) {
      height: 60%;
    }
  `;

  const Title = styled.span`
    color: ${titleColor};
    font-weight: bold;
    font-size: 14pt;
      
    @media screen and (max-width: 1023px) {
      font-size: 12pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 11pt; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10pt;
    }
  `;

  const TextP = styled.p`
    color: ${txtColor};
    height: fit-content;
  `;

  const ClickButton = styled.button`
    display: inline-block;
    color: ${txtColor};
    background-color: ${bgColor};
    border-color: ${txtColor};
    border-style: solid;
    border-width: 2px;
    text-align: center;
    /* transition: ease background-color 0.25s; */
    padding: ${GlobalSettings.paddingAroundHTMLElements};

    &:hover {
      cursor: pointer;
      border-color: transparent;
      background-color: ${GlobalSettings.mainYellowColor};
      color: ${GlobalSettings.mainWhiteColor};
    }

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  `;

  return (
    <MainContainer>
      <Image src={imageSrc} alt={alText} loading="eager" width="300px" height="200px"/>
      <ContentContainer>
        <Title>{title}</Title>
        <TextP>{description}</TextP>
        <ClickButton as="a" href={toref}>{btnText}</ClickButton>
      </ContentContainer>
    </MainContainer>
  );
};

export default ButtonCardComponent;